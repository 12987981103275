const BASE_URL = "/web-admin/schools";
import axios from "./axios";

export default {
  getSchool: (adminId) => axios.get(`${BASE_URL}/${adminId}`),

  getSchools: (schoolName) =>
    axios.get(`${BASE_URL}`, {
      params: {
        schoolName,
      },
    }),

  updateSchool: (adminId, data) => axios.patch(`${BASE_URL}/${adminId}`, data),
};
