const BASE_URL = "/web-admin/classes";
import axios from "./axios";

export default {
  getClasses: (adminId, params) =>
    axios.get(`${BASE_URL}/${adminId}`, {
      params,
    }),
  getClass: (adminId, params) =>
    axios.get(`${BASE_URL}/${adminId}/details`, {
      params,
    }),
  getStudents: (adminId, params) =>
    axios.get(`${BASE_URL}/${adminId}/details/students`, {
      params,
    }),
  deleteClass: (adminId, gradeNum, classNum) =>
    axios.patch(
      `${BASE_URL}/${adminId}/details/reset?gradeNum=${gradeNum}&classNum=${classNum}`,
      {
        newAcademicYear: 1,
        newSemester: 1,
        newTotalStudent: 1,
      }
    ),
  updateClass: (adminId, gradeNum, classNum, data) =>
    axios.patch(
      `${BASE_URL}/${adminId}/details?gradeNum=${gradeNum}&classNum=${classNum}`,
      data
    ),

  createClass: (adminId, data) => axios.post(`${BASE_URL}/${adminId}`, data),
};
