import { useMemo, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountIcon from "@mui/icons-material/AccountCircle";
import { Button, List, ListItemButton } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAdminId } from "../store";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Header({ toggleDrawer, open }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const adminId = useSelector((state) => state.adminId);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("adminId")) {
      dispatch(
        setAdminId({ adminId: JSON.parse(localStorage.getItem("adminId")) })
      );
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);
  const id = useMemo(() => {
    return openPopover ? "simple-popover" : undefined;
  }, [openPopover]);

  const title = useMemo(() => {
    const titleMap = {
      school: "학교 관리",
      teacher: "교사 관리",
      student: "학생 관리",
      class: "학급 관리",
      assignment: "과제 관리",
      discussion: "토론방 관리",
      notice: "공지사항 관리",
    };
    const path = location.pathname;
    if (path === "/") return `Admin - 학교 관리`;
    const keys = Object.keys(titleMap);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (path.includes(key)) {
        return `Admin - ${titleMap[key]}`;
      }
    }
    return `Admin`;
  }, [location]);

  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          {title}
        </Typography>
        {adminId !== null ? (
          <>
            <IconButton color="inherit" onClick={handleClick}>
              <AccountIcon />
            </IconButton>
            <Popover
              id={id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <List>
                <ListItemButton
                  onClick={() => {
                    setAnchorEl(null);
                    navigate("/login");
                  }}
                >
                  로그아웃
                </ListItemButton>
              </List>
            </Popover>
          </>
        ) : (
          <Button
            variant="text"
            sx={{ color: "white" }}
            onClick={() => {
              navigate("/login");
            }}
          >
            로그인
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}
