import React, { useEffect, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import PersonIcon from "@mui/icons-material/Person";
import PersonIcon3 from "@mui/icons-material/Person3";
import PeopleIcon from "@mui/icons-material/People";
import CampaignIcon from "@mui/icons-material/Campaign";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ForumIcon from "@mui/icons-material/Forum";
import DomainIcon from "@mui/icons-material/Domain";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Collapse } from "@mui/material";

const StyledListItemButton = styled(ListItemButton)`
  background-color: ${(props) =>
    props.selected ? "rgba(0, 0, 0, 0.2)" : "white"};
`;

export default function ListItems({ open, toggleDrawer }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [teacherItemShow, setTeacherItemShow] = useState(false);

  useEffect(() => {
    if (!open) {
      setTeacherItemShow(false);
    }
  }, [open]);

  useEffect(() => {
    if (teacherItemShow && !open) {
      toggleDrawer();
    }
  }, [teacherItemShow]);

  return (
    <React.Fragment>
      <StyledListItemButton
        onClick={() => navigate("/school")}
        selected={location.pathname === "/" || location.pathname === "/school"}
      >
        <ListItemIcon>
          <DomainIcon />
        </ListItemIcon>
        <ListItemText primary="학교 관리" />
      </StyledListItemButton>

      <StyledListItemButton
        onClick={() => {
          setTeacherItemShow(!teacherItemShow);
        }}
        selected={location.pathname.includes("teacher")}
      >
        <ListItemIcon>
          <PersonIcon3 />
        </ListItemIcon>
        <ListItemText primary="교사 관리" />
        <ListItemIcon sx={{ justifyContent: "flex-end" }}>
          {teacherItemShow ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
      </StyledListItemButton>
      <Collapse in={teacherItemShow} sx={{ paddingLeft: 2 }}>
        <StyledListItemButton
          onClick={() => navigate("/teacher")}
          selected={location.pathname === "/teacher"}
        >
          <ListItemText primary="- 회원 관리" />
        </StyledListItemButton>
        <StyledListItemButton
          onClick={() => navigate("/teacher/join")}
          selected={location.pathname === "/teacher/join"}
        >
          <ListItemText primary="- 가입 요청 관리" />
        </StyledListItemButton>
        <StyledListItemButton
          onClick={() => navigate("/teacher/modify")}
          selected={location.pathname === "/teacher/modify"}
        >
          <ListItemText primary="- 수정 요청 관리" />
        </StyledListItemButton>
      </Collapse>

      <StyledListItemButton
        onClick={() => navigate("/student")}
        selected={location.pathname.includes("/student")}
      >
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="학생 관리" />
      </StyledListItemButton>
      <StyledListItemButton
        onClick={() => navigate("/class")}
        selected={location.pathname.includes("/class")}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="학급 관리" />
      </StyledListItemButton>
      <StyledListItemButton
        onClick={() => navigate("/assignment")}
        selected={location.pathname.includes("/assignment")}
      >
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="과제 관리" />
      </StyledListItemButton>
      <StyledListItemButton
        onClick={() => navigate("/discussion")}
        selected={location.pathname.includes("/discussion")}
      >
        <ListItemIcon>
          <ForumIcon />
        </ListItemIcon>
        <ListItemText primary="토론방 관리" />
      </StyledListItemButton>
      <StyledListItemButton
        onClick={() => navigate("/notice")}
        selected={location.pathname.includes("/notice")}
      >
        <ListItemIcon>
          <CampaignIcon />
        </ListItemIcon>
        <ListItemText primary="공지사항 관리" />
      </StyledListItemButton>
    </React.Fragment>
  );
}
