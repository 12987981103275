export const students = new Array(9).fill(0).map((_, i) => ({
  index: i + 1,
  uid: i + 1,
  name: `학생${i + 1}`,
  id: `student${i + 1}`,
  grade: 1,
  class: 1,
  no: i + 1,
  mobile: `010-${i + 1}${i + 1}${i + 1}${i + 1}-${i + 1}${i + 1}${i + 1}${
    i + 1
  }`,
  email: `student${i + 1}@gmail.com`,
  birth: `2008-0${i + 1}-0${i + 1}`,
}));

export const student = {
  index: 1,
  uid: 1,
  name: `학생${1}`,
  id: `student${1}`,
  grade: 1,
  class: 1,
  no: 1,
  mobile: `010-${1}${1}${1}${1}-${1}${1}${1}${1}`,
  email: `student${1}@gmail.com`,
  birth: `2008-0${1}-0${1}`,
  rate: 80,
  score: 50,
  num: 10,
  assignments: new Array(3).fill(0).map((_, i) => ({
    index: i + 1,
    name: `과제${i + 1}`,
    submission: true,
    date: `2022-10-20`,
    score: 80,
  })),
};
