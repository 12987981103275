export const assignmentList = new Array(9).fill(0).map((_, i) => ({
  index: i + 1,
  "과제 번호": i + 1,
  과제명: `과제${i}`,
  "과제 기한": `2022-11-0${i + 1}`,
  "제출 인원": `10`,
  "과제 진행 상태": `진행중`,
}));

export const assignmentItem = {
  과제명: "과제",
  과목: "수학",
  "세부 사항": "세부 사항",
  "과제 유형": "과제 유형",
  기한: "2022-12-03",
  제출률: 80,
  "평균 정답률": 80,
  등록일: "2022-10-10",
  "마감 여부": "마감전",

  list: new Array(9).fill(0).map((_, i) => ({
    index: i + 1,
    "학생 번호": i + 1,
    이름: `학생${i + 1}`,
    "제출 여부": true,
    "제출 날짜": `2022-11-0${i + 1}`,
    결과: "결과",
    "소요 시간": 5,
    만족도: 5,
  })),
};
