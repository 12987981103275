import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import teacherApi from "../api/teacher";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Modal,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import color from "../utils/color";
import { discussionItem } from "../mockupdata/discussion";
import { useNavigate, useParams } from "react-router-dom";
import KeyTableCell from "../components/KeyTableCell";
import ValueTableCell from "../components/ValueTableCell";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import teacher from "../api/teacher";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function DiscussionDetail() {
  const dispatch = useDispatch();
  const params = useParams();
  const [discussion, setDiscussion] = useState(discussionItem);

  const fetchClass = async () => {
    return;
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const requestParams = { idx: params.idx };
      const res = await teacherApi.getTeacher(adminId, requestParams);
      if (res?.data?.isSuccess) {
        setTeacher({ ...teacher, ...res.data.result });
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    fetchClass();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.2)",
          p: 3,
          width: "100%",
          borderRadius: "8px",
          mb: 4,
        }}
      >
        <Typography variant="h5" component="div" mb={2}>
          {discussion["질문 제목"]}
        </Typography>
        <Box>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {discussion["작성자"]} | {discussion["작성 일시"]}
          </Typography>
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Typography sx={{ fontSize: 16 }} gutterBottom>
          {discussion["질문 내용"]}
        </Typography>
      </Box>
      <Typography sx={{ alignSelf: "flex-start" }} variant="h6" gutterBottom>
        답변
      </Typography>

      {discussion.answers.map((answer) => (
        <Box
          sx={{
            border: "1px solid rgba(0,0,0,0.2)",
            p: 3,
            width: "100%",
            borderRadius: "8px",
            mb: 3,
          }}
        >
          <Typography variant="h6" component="div" mb={2}>
            {answer["답변 제목"]}
          </Typography>
          <Box>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              {answer["답변 작성자"]} | {answer["답변 일시"]}
            </Typography>
          </Box>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography sx={{ fontSize: 16 }} gutterBottom>
            {answer["답변 내용"]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
