import axios from "axios";

/** ToDo: setAuthHeaders 추가될 시, 대체 */
const getHeaders = () => {
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json",
    "X-Access-Token": JSON.parse(window.localStorage.getItem("jwt")),
  };
  return headers;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 1000 * 10,
  headers: getHeaders(),
});

instance.interceptors.request.use((config) => {
  config.headers = getHeaders();
  return config;
});

export default instance;
