import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import classApi from "../api/classApi";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import color from "../utils/color";
import { classItem as classItemMokup } from "../mockupdata/class";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import KeyTableCell from "../components/KeyTableCell";
import ValueTableCell from "../components/ValueTableCell";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import teacher from "../api/teacher";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function ClassDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [classItem, setClassItem] = useState({});
  const [students, setStudents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const handleModifyButtonClick = () => {
    setModalOpen(true);
  };

  const handleResetButtonClick = () => {
    setDialogOpen(true);
  };

  const deleteClass = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const { gradeNum, classNum } = params;
      const res = await classApi.deleteClass(adminId, gradeNum, classNum);
      if (res?.data?.isSuccess) {
        setDialogOpen(false);
        alert("초기화 완료.");
        fetchClass();
        fetchStudents();
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      newAcademicYear: +data.get("newAcademicYear"),
      newSemester: +data.get("newSemester"),
      newTotalStudent: +data.get("newTotalStudent"),
    };
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const { gradeNum, classNum } = params;
      const res = await classApi.updateClass(
        adminId,
        gradeNum,
        classNum,
        formData
      );
      if (res?.data?.isSuccess) {
        alert("수정이 완료되었습니다.");
        fetchClass();
        setModalOpen(false);
      }
    } catch {
      alert("정보 수정 실패");
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const fetchClass = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const { gradeNum, classNum } = params;
      const teacherName = searchParams.get("teacherName");
      const requestParams = {
        gradeNum,
        classNum,
        teacherName,
      };
      const res = await classApi.getClass(adminId, requestParams);
      if (res?.data?.isSuccess) {
        setClassItem({ ...classItem, ...res.data.result });
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const fetchStudents = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const { gradeNum, classNum } = params;
      const requestParams = {
        gradeNum,
        classNum,
        page: page - 1,
      };
      const res = await classApi.getStudents(adminId, requestParams);
      if (res?.data?.isSuccess) {
        console.log(res);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    fetchClass();
    fetchStudents();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <KeyTableCell>학사년도</KeyTableCell>
              <ValueTableCell>{classItem.academicYear}</ValueTableCell>
              <KeyTableCell>학기</KeyTableCell>
              <ValueTableCell>{classItem.semester}</ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>담당 교사</KeyTableCell>
              <ValueTableCell>{classItem.teacherName}</ValueTableCell>
              <KeyTableCell>담당 교사 과목</KeyTableCell>
              <ValueTableCell>{classItem.teacherSubjectName}</ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>학급 학생 수</KeyTableCell>
              <ValueTableCell>{classItem.totalStudent}</ValueTableCell>
              <KeyTableCell>평균 집중도</KeyTableCell>
              <ValueTableCell>{classItem.averageConcentration}</ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>누적 과제 수</KeyTableCell>
              <ValueTableCell>{classItem.totalAssignment}</ValueTableCell>
              <KeyTableCell>진행 과제 수</KeyTableCell>
              <ValueTableCell>{classItem.assignmentInProgress}</ValueTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          onClick={handleModifyButtonClick}
        >
          수정
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleResetButtonClick}
        >
          초기화
        </Button>
      </Box>
      <Typography
        variant="h6"
        mb={2}
        sx={{
          alignSelf: "flex-start",
        }}
      >
        학생 목록
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: color.mainColor1 }}>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>학생 이름</TableCell>
              <TableCell>학생 번호</TableCell>
              <TableCell>과제 제출률</TableCell>
              <TableCell>집중도 점수</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students?.map((student) => (
              <TableRow
                key={student.index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "#eee" },
                  cursor: "pointer",
                }}
              >
                <TableCell>{student.index}</TableCell>
                <TableCell>{student["학생 이름"]}</TableCell>
                <TableCell>{student["학생 번호"]}</TableCell>
                <TableCell>{student["과제 제출률"]}</TableCell>
                <TableCell>{student["집중도 점수"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPage > 0 && (
        <Pagination
          page={page}
          count={totalPage}
          onChange={(e, v) => {
            setPage(v);
          }}
        />
      )}

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 2 }}>
          <DialogContentText id="alert-dialog-description" color="black">
            정말 초기화하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setDialogOpen(false)}
            variant="contained"
            sx={{ backgroundColor: "gray" }}
            size="small"
          >
            취소
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={deleteClass}
          >
            초기화
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Box
          sx={style}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          gap={3}
        >
          <TextField
            fullWidth
            id="newAcademicYear"
            label="학사년도"
            name="newAcademicYear"
            sx={{ mb: 2 }}
            type="number"
            defaultValue={classItem.academicYear}
          />
          <TextField
            fullWidth
            id="newSemester"
            label="학기"
            name="newSemester"
            sx={{ mb: 2 }}
            type="number"
            defaultValue={classItem.semester}
          />
          <TextField
            fullWidth
            id="newTotalStudent"
            label="학급 학생 수"
            name="newTotalStudent"
            sx={{ mb: 2 }}
            type="number"
            defaultValue={classItem.totalStudent}
          />
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" sx={{ mr: 1 }} type="submit">
              저장
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
