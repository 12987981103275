import React, { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { List, ListItemButton, Modal, Pagination } from "@mui/material";
import schoolApi from "../api/school";
import authApi from "../api/auth";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import SearchIcon from "@mui/icons-material/Search";
import {
  isEmpty,
  isValidId,
  isValidPassword,
  isValidPhoneNumber,
} from "../utils/validate";
import { useNavigate } from "react-router-dom";

const theme = createTheme();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [schools, setSchools] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedSchool, setSelectedSchool] = useState({});
  const pageLimit = 5;

  const [error, setError] = useState({
    id: {
      isError: false,
      message: "",
    },
    password: {
      isError: false,
      message: "",
    },
    passwordConfirm: {
      isError: false,
      message: "",
    },
    mobile: {
      isError: false,
      message: "",
    },
    school: {
      isError: false,
      message: "",
    },
    numFirstGrade: {
      isError: false,
      message: "",
    },
    numSecondGrade: {
      isError: false,
      message: "",
    },
    numThirdGrade: {
      isError: false,
      message: "",
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let isValid = true;
    const newError = { ...error };
    if (!isValidId(data.get("id"))) {
      isValid = false;
      newError.id = {
        isError: true,
        message: "6자리 이상 영문, 숫자만 입력해주세요.",
      };
    } else {
      newError.id = {
        isError: false,
        message: "",
      };
    }
    if (!isValidPassword(data.get("password"))) {
      isValid = false;
      newError.password = {
        isError: true,
        message: "영문, 숫자 포함 8자리 이상 20자리 이하로 입력해주세요.",
      };
    } else {
      newError.password = {
        isError: false,
        message: "",
      };
    }
    if (data.get("password") !== data.get("passwordConfirm")) {
      isValid = false;
      newError.passwordConfirm = {
        isError: true,
        message: "비밀번호가 일치하지 않습니다.",
      };
    } else {
      newError.passwordConfirm = {
        isError: false,
        message: "",
      };
    }
    if (!isValidPhoneNumber(data.get("mobile"))) {
      isValid = false;
      newError.mobile = {
        isError: true,
        message: "-를 포함한 휴대폰 번호를 입력해주세요.",
      };
    } else {
      newError.mobile = {
        isError: false,
        message: "",
      };
    }
    if (isEmpty(selectedSchool)) {
      isValid = false;
      newError.school = {
        isError: true,
        message: "학교를 입력해주세요.",
      };
    } else {
      newError.school = {
        isError: false,
        message: "",
      };
    }
    if (isEmpty(data.get("numFirstGrade"))) {
      isValid = false;
      newError.numFirstGrade = {
        isError: true,
        message: "1학년 학급 수를 입력해주세요.",
      };
    } else {
      newError.numFirstGrade = {
        isError: false,
        message: "",
      };
    }
    if (isEmpty(data.get("numSecondGrade"))) {
      isValid = false;
      newError.numSecondGrade = {
        isError: true,
        message: "2학년 학급 수를 입력해주세요.",
      };
    } else {
      newError.numSecondGrade = {
        isError: false,
        message: "",
      };
    }
    if (isEmpty(data.get("numThirdGrade"))) {
      isValid = false;
      newError.numThirdGrade = {
        isError: true,
        message: "3학년 학급 수를 입력해주세요.",
      };
    } else {
      newError.numThirdGrade = {
        isError: false,
        message: "",
      };
    }
    setError(newError);
    if (!isValid) return;

    try {
      dispatch(setLoading({ loading: true }));
      const requestData = {
        loginId: data.get("id"),
        password: data.get("password"),
        confirmPassword: data.get("passwordConfirm"),
        phone: data.get("mobile"),
        schoolInfoId: selectedSchool.schoolId,
        numberOfFirstGradeClass: +data.get("numFirstGrade"),
        numberOfSecondGradeClass: +data.get("numSecondGrade"),
        numberOfThirdGradeClass: +data.get("numThirdGrade"),
      };
      const res = await authApi.signup(requestData);
      if (res?.data?.isSuccess) {
        alert("회원가입 성공.");
        navigate("/login");
      } else {
        alert(
          `회원가입 실패 ${res?.data?.message ? `(${res.data.message})` : ""}`
        );
      }
    } catch {
      alert(`회원가입 실패`);
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const searchSchools = async () => {
    dispatch(setLoading({ loading: true }));
    try {
      const res = await schoolApi.getSchools(searchValue);
      setPage(1);
      if (res?.data?.isSuccess) {
        setSchools(res.data.result);
      } else {
        setSchools([]);
      }
    } catch {
      setPage(1);
      setSchools([]);
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const pagedSchools = useMemo(() => {
    const startIndex = (page - 1) * pageLimit;
    return schools.slice(startIndex, startIndex + 5);
  }, [schools, page]);

  const pageCount = useMemo(() => {
    return Math.ceil(schools.length / pageLimit);
  }, [schools]);

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={searchModalOpen}
        onClose={() => {
          setSearchModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <TextField
              id="standard-basic"
              label="학교이름"
              variant="standard"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              fullWidth
              onKeyUp={(e) => {
                if (e.key === "Enter") searchSchools();
              }}
            />
            <Button onClick={searchSchools}>검색</Button>
          </Box>
          <List>
            {pagedSchools.map((school) => (
              <ListItemButton
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                key={school.schoolId}
                onClick={() => {
                  setSelectedSchool(school);
                  setSearchModalOpen(false);
                }}
              >
                <Typography variant="h6">{school.schoolName}</Typography>
                <Typography variant="body2">{school.schoolAddress}</Typography>
              </ListItemButton>
            ))}
          </List>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {pageCount > 0 && (
              <Pagination
                page={page}
                count={pageCount}
                onChange={(e, v) => {
                  setPage(v);
                }}
              />
            )}
          </Box>
        </Box>
      </Modal>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            회원가입
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="id"
                  label="아이디"
                  name="id"
                  autoComplete="id"
                  autoFocus
                  error={error.id.isError}
                  helperText={error.id.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  label="비밀번호"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={error.password.isError}
                  helperText={error.password.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="passwordConfirm"
                  label="비밀번호 확인"
                  type="password"
                  id="passwordConfirm"
                  error={error.passwordConfirm.isError}
                  helperText={error.passwordConfirm.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="mobile"
                  label="휴대폰 번호"
                  id="mobile"
                  error={error.mobile.isError}
                  helperText={error.mobile.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="school"
                  id="school"
                  InputProps={{
                    readOnly: true,
                    endAdornment: <SearchIcon color="action" />,
                  }}
                  placeholder="학교 이름"
                  onClick={() => {
                    setSearchModalOpen(true);
                  }}
                  value={selectedSchool?.schoolName}
                  error={error.school.isError}
                  helperText={error.school.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="numFirstGrade"
                  label="1학년 학급 수"
                  id="numFirstGrade"
                  type="number"
                  error={error.numFirstGrade.isError}
                  helperText={error.numFirstGrade.message}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="numSecondGrade"
                  label="2학년 학급 수"
                  id="numSecondGrade"
                  type="number"
                  error={error.numSecondGrade.isError}
                  helperText={error.numSecondGrade.message}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="numThirdGrade"
                  label="3학년 학급 수"
                  id="numThirdGrade"
                  type="number"
                  error={error.numThirdGrade.isError}
                  helperText={error.numThirdGrade.message}
                  defaultValue={0}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              회원가입
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
