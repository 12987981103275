import { TableCell } from "@mui/material";
import styled from "styled-components";
import color from "../utils/color";

const KeyTableCell = styled(TableCell)`
  background-color: ${color.mainColor1};
  font-size: 16px;
  font-weight: 600;
  width: 10%;
  min-width: 150px;
`;
export default KeyTableCell;
