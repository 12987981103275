export const teachers = [
  {
    index: 0,
    teacherId: 0,
    teacherName: "김선생0",
    teacherLoginId: "teacher0",
    teacherPhone: "010-0000-0000",
    teacherEmail: "teacher0@gmail.com",
    teacherBirthYear: "1990",
    teacherBirthMonth: "12",
    teacherBirthDay: "12",
  },
  {
    index: 1,
    teacherId: 1,
    teacherName: "김선생1",
    teacherLoginId: "teacher1",
    teacherPhone: "010-1111-1111",
    teacherEmail: "teacher1@gmail.com",
    teacherBirthYear: "1990",
    teacherBirthMonth: "12",
    teacherBirthDay: "12",
  },
  {
    index: 2,
    teacherId: 2,
    teacherName: "김선생2",
    teacherLoginId: "teacher2",
    teacherPhone: "010-2222-2222",
    teacherEmail: "teacher2@gmail.com",
    teacherBirthYear: "1990",
    teacherBirthMonth: "12",
    teacherBirthDay: "12",
  },
  {
    index: 3,
    teacherId: 3,
    teacherName: "김선생3",
    teacherLoginId: "teacher3",
    teacherPhone: "010-3333-3333",
    teacherEmail: "teacher3@gmail.com",
    teacherBirthYear: "1990",
    teacherBirthMonth: "12",
    teacherBirthDay: "12",
  },
  {
    index: 4,
    teacherId: 4,
    teacherName: "김선생4",
    teacherLoginId: "teacher4",
    teacherPhone: "010-4444-4444",
    teacherEmail: "teacher4@gmail.com",
    teacherBirthYear: "1990",
    teacherBirthMonth: "12",
    teacherBirthDay: "12",
  },
  {
    index: 5,
    teacherId: 5,
    teacherName: "김선생5",
    teacherLoginId: "teacher5",
    teacherPhone: "010-5555-5555",
    teacherEmail: "teacher5@gmail.com",
    teacherBirthYear: "1990",
    teacherBirthMonth: "12",
    teacherBirthDay: "12",
  },
  {
    index: 6,
    teacherId: 6,
    teacherName: "김선생6",
    teacherLoginId: "teacher6",
    teacherPhone: "010-6666-6666",
    teacherEmail: "teacher6@gmail.com",
    teacherBirthYear: "1990",
    teacherBirthMonth: "12",
    teacherBirthDay: "12",
  },
  {
    index: 7,
    teacherId: 7,
    teacherName: "김선생7",
    teacherLoginId: "teacher7",
    teacherPhone: "010-7777-7777",
    teacherEmail: "teacher7@gmail.com",
    teacherBirthYear: "1990",
    teacherBirthMonth: "12",
    teacherBirthDay: "12",
  },
  {
    index: 8,
    teacherId: 8,
    teacherName: "김선생8",
    teacherLoginId: "teacher8",
    teacherPhone: "010-8888-8888",
    teacherEmail: "teacher8@gmail.com",
    teacherBirthYear: "1990",
    teacherBirthMonth: "12",
    teacherBirthDay: "12",
  },
  {
    index: 9,
    teacherId: 9,
    teacherName: "김선생9",
    teacherLoginId: "teacher9",
    teacherPhone: "010-9999-9999",
    teacherEmail: "teacher9@gmail.com",
    teacherBirthYear: "1990",
    teacherBirthMonth: "12",
    teacherBirthDay: "12",
  },
];

export const teacher = {
  index: 8,
  teacherId: 8,
  teacherName: "김선생8",
  teacherLoginId: "teacher8",
  teacherPhone: "010-8888-8888",
  teacherEmail: "teacher8@gmail.com",
  teacherBirthYear: "1990",
  teacherBirthMonth: "12",
  teacherBirthDay: "12",
  classList: [
    {
      grade: 1,
      class: 1,
      numOfStudents: 30,
      numberOfAssignment: 100,
      completedAssignment: 40,
      performanceRateAssignment: 40,
    },
    {
      grade: 2,
      class: 2,
      numOfStudents: 34,
      numberOfAssignment: 100,
      completedAssignment: 50,
      performanceRateAssignment: 50,
    },
  ],
};
