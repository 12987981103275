import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import teacherApi from "../api/teacher";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Modal,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import color from "../utils/color";
import { noticeItem } from "../mockupdata/notice";
import { useNavigate, useParams } from "react-router-dom";
import KeyTableCell from "../components/KeyTableCell";
import ValueTableCell from "../components/ValueTableCell";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import noticeApi from "../api/notice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function NoticeDetail() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [notice, setNotice] = useState(noticeItem);
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleModifyButtonClick = () => {
    setModalOpen(true);
  };

  const handleDeleteButtonClick = () => {
    setDialogOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const formData = {
      title: data.get("title"),
      content: data.get("content"),
    };
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await noticeApi.updateNotice(adminId, params.id, formData);
      if (res?.data?.isSuccess) {
        alert("수정이 완료되었습니다.");
        fetchNotice();
        setModalOpen(false);
      } else {
        alert(res?.data?.message);
      }
    } catch {
      alert("정보 수정 실패");
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const deleteNotice = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await noticeApi.deleteNotice(adminId, params.id);
      if (res?.data?.isSuccess) {
        alert("삭제 완료");
        navigate("/notice", { replace: true });
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const fetchNotice = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));

      const res = await noticeApi.getNotice(adminId, params.id);
      if (res?.data?.isSuccess) {
        setNotice(res.data.result);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    fetchNotice();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 2 }}>
          <DialogContentText id="alert-dialog-description" color="black">
            정말 삭제하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setDialogOpen(false)}
            variant="contained"
            sx={{ backgroundColor: "gray" }}
            size="small"
          >
            취소
          </Button>
          <Button
            onClick={deleteNotice}
            variant="contained"
            color="error"
            size="small"
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Box sx={style} component="form" noValidate onSubmit={handleSubmit}>
          <TextField
            fullWidth
            id="title"
            label="제목"
            name="title"
            sx={{ mb: 2 }}
            defaultValue={notice["title"]}
          />
          {/* <TextField
            fullWidth
            id="teacherName"
            label="작성자"
            name="작성자"
            sx={{ mb: 2 }}
            defaultValue={notice["teacherName"]}
          />
          <TextField
            id="createdAt"
            label="등록일"
            type="date"
            defaultValue={notice["createdAt"]}
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              console.log(e.target.value);
            }}
          /> */}
          <TextField
            id="content"
            label="내용"
            name="content"
            multiline
            rows={4}
            fullWidth
            defaultValue={notice["content"]}
            sx={{ mb: 2 }}
          />

          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => setModalOpen(false)}
              variant="contained"
              sx={{ backgroundColor: "gray" }}
              size="small"
            >
              취소
            </Button>
            <Button variant="contained" sx={{ ml: 1 }} type="submit">
              저장
            </Button>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.2)",
          p: 3,
          width: "100%",
          borderRadius: "8px",
          mb: 4,
        }}
      >
        <Typography variant="h5" component="div" mb={2}>
          {notice["title"]}
        </Typography>
        <Box>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {notice["type"]}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            {notice["gradeNum"]}학년 {notice["classNum"]}반
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              {notice["teacherName"]} | {notice["createdAt"]}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary">
              조회수 : {notice["viewCount"]}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Typography sx={{ fontSize: 16 }} gutterBottom>
          {notice["content"]}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          onClick={handleModifyButtonClick}
        >
          수정
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteButtonClick}
        >
          삭제
        </Button>
      </Box>
    </Box>
  );
}
