import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import schoolApi from "../api/school";
import { setLoading } from "../store";
import DomainIcon from "@mui/icons-material/Domain";
import KeyTableCell from "../components/KeyTableCell";
import ValueTableCell from "../components/ValueTableCell";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function School() {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [school, setSchool] = useState({
    completedAssignment: 0,
    numberOfAssignment: 0,
    performanceRateAssignment: 0,
    schoolName: "",
    studentNum: 0,
    teacherNum: 0,
    firstGrade: { numberOfClass: 0, numberOfStudents: 0 },
    secondGrade: { numberOfClass: 0, numberOfStudents: 0 },
    thirdGrade: { numberOfClass: 0, numberOfStudents: 0 },
  });

  useEffect(() => {
    fetchSchool();
  }, []);

  const fetchSchool = async () => {
    dispatch(setLoading({ loading: true }));
    const adminId = JSON.parse(localStorage.getItem("adminId"));
    try {
      const res = await schoolApi.getSchool(adminId);
      setSchool(res.data.result);
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      schoolName: data.get("schoolName"),
      numOfClassFirst: +data.get("numOfClassFirst"),
      numOfClassSecond: +data.get("numOfClassSecond"),
      numOfClassThird: +data.get("numOfClassThird"),
    };
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await schoolApi.updateSchool(adminId, formData);
      if (res?.data?.isSuccess) {
        alert("수정이 완료되었습니다.");
        fetchSchool();
        setModalOpen(false);
      }
    } catch {
      alert("정보 수정 실패");
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  return (
    <Box>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            gap={3}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <TextField
              fullWidth
              id="schoolName"
              label="학교이름"
              name="schoolName"
              autoFocus
              defaultValue={school.schoolName}
            />
            <TextField
              fullWidth
              id="numOfClassFirst"
              label="1학년 학급 수"
              name="numOfClassFirst"
              type="number"
              defaultValue={school.firstGrade?.numberOfClass}
            />
            <TextField
              fullWidth
              id="numOfClassSecond"
              label="2학년 학급 수"
              name="numOfClassSecond"
              type="number"
              defaultValue={school.secondGrade?.numberOfClass}
            />
            <TextField
              fullWidth
              id="numOfClassThird"
              label="3학년 학급 수"
              name="numOfClassThird"
              type="number"
              defaultValue={school.thirdGrade?.numberOfClass}
            />
            <Button type="submit" fullWidth variant="contained">
              완료
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
        <DomainIcon sx={{ mr: 1 }} />
        <Typography variant="h5">{school.schoolName}</Typography>
      </Box>

      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{}} aria-label="simple table">
          <TableBody>
            <TableRow>
              <KeyTableCell>총 학생 수</KeyTableCell>
              <ValueTableCell>{school.studentNum}</ValueTableCell>
              <KeyTableCell>총 교사 수</KeyTableCell>
              <ValueTableCell>{school.teacherNum}</ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>1학년 학급 수</KeyTableCell>
              <ValueTableCell>
                {school.firstGrade?.numberOfClass}
              </ValueTableCell>
              <KeyTableCell>1학년 학생 수</KeyTableCell>
              <ValueTableCell>
                {school.firstGrade?.numberOfStudents}
              </ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>2학년 학급 수</KeyTableCell>
              <ValueTableCell>
                {school.secondGrade?.numberOfClass}
              </ValueTableCell>
              <KeyTableCell>2학년 학생 수</KeyTableCell>
              <ValueTableCell>
                {school.secondGrade?.numberOfStudents}
              </ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>3학년 학급 수</KeyTableCell>
              <ValueTableCell>
                {school.thirdGrade?.numberOfClass}
              </ValueTableCell>
              <KeyTableCell>3학년 학생 수</KeyTableCell>
              <ValueTableCell>
                {school.thirdGrade?.numberOfStudents}
              </ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>총 과제 부여 수</KeyTableCell>
              <ValueTableCell>{school.numberOfAssignment}</ValueTableCell>
              <KeyTableCell>총 과제 수행 수</KeyTableCell>
              <ValueTableCell>{school.completedAssignment}</ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>과제 수행률</KeyTableCell>
              <ValueTableCell>
                {school.performanceRateAssignment === "NaN"
                  ? 0
                  : school.performanceRateAssignment.toFixed(2)}
                %
              </ValueTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" onClick={openModal}>
        수정
      </Button>
    </Box>
  );
}
