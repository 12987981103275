import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import assignmentApi from "../api/assignment";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Pagination,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import color from "../utils/color";
import { assignmentList } from "../mockupdata/assignment";
import { useNavigate } from "react-router-dom";

export default function Assignment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [assignments, setAssignments] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("name");

  const handleassignmentClick = (item) => {
    navigate(`/assignment/${item.assignmentNumber}`);
  };

  const fetchAssignments = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const params = { page: page - 1, [searchType]: searchValue };
      const res = await assignmentApi.getAssignments(adminId, params);
      if (res?.data?.isSuccess) {
        setAssignments(res.data.result.adminAssignments);
        setTotalPage(res.data.result.totalPage);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    fetchAssignments();
  }, [page]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <FormControl>
          <Select
            inputProps={{ "aria-label": "Without label" }}
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            sx={{ height: "40px" }}
          >
            <MenuItem value="name">과제명</MenuItem>
            <MenuItem value="number">과제 번호</MenuItem>
          </Select>
        </FormControl>
        <Box
          component="form"
          onSubmit={(e) => {
            if (page === 1) fetchAssignments();
            else setPage(1);
            e.preventDefault();
          }}
        >
          <TextField
            id="search"
            variant="outlined"
            sx={{ alignSelf: "flex-end", mb: 2, width: "250px", ml: 2 }}
            size="small"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: color.mainColor1 }}>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>과제 번호</TableCell>
              <TableCell>과제명</TableCell>
              <TableCell>과제 기한</TableCell>
              <TableCell>제출 인원</TableCell>
              <TableCell>과제 진행 상태</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assignments.map((assignment) => (
              <TableRow
                key={assignment.idx}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "#eee" },
                  cursor: "pointer",
                }}
                onClick={() => handleassignmentClick(assignment)}
              >
                <TableCell>{assignment.idx}</TableCell>
                <TableCell>{assignment["assignmentNumber"]}</TableCell>
                <TableCell>{assignment["assignmentName"]}</TableCell>
                <TableCell>
                  {assignment["assignmentEndAt"]?.slice(0, -5)}
                </TableCell>
                <TableCell>{assignment["assignmentSubmitStatus"]}</TableCell>
                <TableCell>{assignment["assignmentProcessStatus"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPage > 0 && (
        <Pagination
          page={page}
          count={totalPage}
          onChange={(e, v) => {
            setPage(v);
          }}
        />
      )}
    </Box>
  );
}
