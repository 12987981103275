export const isEmpty = function (value) {
  return (
    value === "" ||
    value === null ||
    value === undefined ||
    (typeof value === "object" && !Object.keys(value).length)
  );
};

export function isValidPhoneNumber(phoneNumber) {
  return phoneNumber.match(/^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/);
}

export function isValidId(id) {
  var regType1 = /^[A-Za-z0-9]{6,}$/;
  return id.match(regType1);
}

export function isValidPassword(password) {
  return password.match(/^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,20}$/);
}
