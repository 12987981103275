import { Button, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function NoticeForm({
  open,
  closeModal,
  title,
  content,
  titleChange,
  contentChange,
  createNotice,
}) {
  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={style}>
        <TextField
          fullWidth
          id="제목"
          label="제목"
          name="제목"
          sx={{ mb: 2 }}
          value={title}
          onChange={titleChange}
        />

        <TextField
          id="내용"
          label="내용"
          multiline
          rows={4}
          fullWidth
          sx={{ mb: 2 }}
          value={content}
          onChange={contentChange}
        />
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={closeModal}
            variant="contained"
            sx={{ backgroundColor: "gray" }}
            size="small"
          >
            취소
          </Button>
          <Button variant="contained" sx={{ ml: 1 }} onClick={createNotice}>
            저장
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
