export const noticeList = new Array(9).fill(0).map((_, i) => ({
  index: i + 1,
  종류: `종류${i}`,
  학년: (i % 3) + 1,
  학급: (i % 5) + 1,
  제목: `제목${i + 1}`,
  작성자: `작성자${i + 1}`,
  등록일: `2022-11-0${i + 1}`,
}));

export const noticeItem = {
  종류: "종류",
  학년: "1",
  학급: "3",
  제목: "제목",
  작성자: "작성자",
  등록일: "2022-11-09",
  "공지사항 내용":
    "공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 공지사항 ",
  조회수: "9",
};
