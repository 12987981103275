import { createAction, createReducer } from "@reduxjs/toolkit";
import { createStore } from "redux";

export const setLoading = createAction("SET_LOADING");
export const setAdminId = createAction("SET_ADMIN_ID");

const initialState = {
  loading: false,
  adminId: null,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setLoading, (state, action) => {
    state.loading = action.payload.loading;
  });
  builder.addCase(setAdminId, (state, action) => {
    state.adminId = action.payload.adminId;
  });
});

const store = createStore(reducer);
export default store;
