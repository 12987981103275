import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import noticeApi from "../api/notice";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Pagination,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import color from "../utils/color";
import { useNavigate } from "react-router-dom";
import NoticeForm from "./NoticeForm";

export default function Notice() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [notices, setNotices] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("title");

  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const fetchNotices = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const params = { page: page - 1, [searchType]: searchValue };
      const res = await noticeApi.getNotices(adminId, params);
      if (res?.data?.isSuccess) {
        setNotices(res.data.result.notices);
        setTotalPage(res.data.result.totalPage);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    fetchNotices();
  }, [page]);

  const handleassignmentClick = (item) => {
    navigate(`/notice/${item.noticeId}`);
  };

  const createNotice = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const data = {
        title,
        content,
      };
      const res = await noticeApi.createNotice(adminId, data);
      if (res?.data?.isSuccess) {
        alert("등록완료");
        setModalOpen(false);
        fetchNotices();
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <NoticeForm
        open={modalOpen}
        title={title}
        content={content}
        closeModal={() => {
          setModalOpen(false);
        }}
        titleChange={(e) => setTitle(e.target.value)}
        contentChange={(e) => setContent(e.target.value)}
        createNotice={createNotice}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          mb: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            setModalOpen(true);
            setTitle("");
            setContent("");
          }}
        >
          글쓰기
        </Button>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl>
            <Select
              inputProps={{ "aria-label": "Without label" }}
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              sx={{ height: "40px" }}
            >
              <MenuItem value="title">제목</MenuItem>
              <MenuItem value="name">작성자</MenuItem>
            </Select>
          </FormControl>
          <Box
            component="form"
            onSubmit={(e) => {
              if (page === 1) fetchNotices();
              else setPage(1);
              e.preventDefault();
            }}
          >
            <TextField
              id="search"
              variant="outlined"
              sx={{ width: "250px", ml: 2 }}
              size="small"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </Box>
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: color.mainColor1 }}>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>학년</TableCell>
              <TableCell>학급</TableCell>
              <TableCell>제목</TableCell>
              <TableCell>작성자</TableCell>
              <TableCell>등록일</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notices.map((notice, i) => (
              <TableRow
                key={notice.noticeId}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "#eee" },
                  cursor: "pointer",
                }}
                onClick={() => handleassignmentClick(notice)}
              >
                <TableCell>{notice.idx}</TableCell>
                <TableCell>{notice["gradeNum"]}</TableCell>
                <TableCell>{notice["classNum"]}</TableCell>
                <TableCell>{notice["noticeTitle"]}</TableCell>
                <TableCell>{notice["teacherName"]}</TableCell>
                <TableCell>{notice["noticeCreatedAt"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {totalPage > 0 && (
        <Pagination
          page={page}
          count={totalPage}
          onChange={(e, v) => {
            setPage(v);
          }}
        />
      )}
    </Box>
  );
}
