import { useSelector } from "react-redux";
import MainLayout from "./layout/MainLayout";
import Loading from "./components/Loading";

export default function App() {
  const loading = useSelector((state) => state.loading);
  return (
    <>
      <MainLayout />
      {loading && <Loading />}
    </>
  );
}
