import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import teacherApi from "../api/teacher";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Pagination,
  Typography,
} from "@mui/material";
import color from "../utils/color";
import KeyTableCell from "../components/KeyTableCell";
import ValueTableCell from "../components/ValueTableCell";
import styled from "styled-components";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 640,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function TeacherJoin() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [teachers, setTeachers] = useState([
    {
      index: 0,
      teacherId: 0,
      teacherName: "김수미",
      teacherLoginId: "teacher123",
      teacherPhone: "010-1234-1234",
      teacherEmail: "teacher123@gmail.com",
      teacherBirthYear: "1990",
      teacherBirthMonth: "12",
      teacherBirthDay: "12",
    },
  ]);
  const [selectedTeacher, setSelectedTeacher] = useState({
    newIdRequested: null,
    newNameRequested: null,
    newSchoolIdRequested: null,
    newSchoolName: null,
    SchoolName: null,
    teacherId: null,
    teacherIdx: null,
    teacherName: null,
    teacherschoolId: null,
  });
  const [modalOpen, setModalOpen] = useState(false);

  const handleTableRowClick = async (teacherId) => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await teacherApi.getModifyRequestTeacher(adminId, teacherId);
      if (res?.data?.isSuccess) {
        setSelectedTeacher(res.data.result);
        setModalOpen(true);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const fetchTeachers = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const params = { page: page - 1 };
      const res = await teacherApi.getModifyRequestTeachers(adminId, params);
      if (res?.data?.isSuccess) {
        setTeachers(res.data.result.teachers);
        setTotalPage(res.data.result.totalPage);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const refuse = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const res = await teacherApi.refuseTeacherModify(
        selectedTeacher.teacherIdx
      );
      if (res?.data?.isSuccess) {
        alert("요청이 거절되었습니다.");
        setModalOpen(false);
        fetchTeachers();
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const accept = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await teacherApi.acceptTeacherModify(
        adminId,
        selectedTeacher.teacherIdx
      );
      if (res?.data?.isSuccess) {
        alert("요청이 승인되었습니다.");
        setModalOpen(false);
        fetchTeachers();
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, [page]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Box sx={style}>
          <TableContainer component={Box}>
            <Table sx={{}} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <KeyTableCell>UID</KeyTableCell>
                  <ValueTableCell>{selectedTeacher.teacherIdx}</ValueTableCell>
                  <KeyTableCell>이름</KeyTableCell>
                  <ValueTableCell>{selectedTeacher.teacherName}</ValueTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h6" my={2}>
            변경 전
          </Typography>
          <TableContainer component={Box}>
            <Table sx={{}} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <KeyTableCell>아이디</KeyTableCell>
                  <ValueTableCell>{selectedTeacher.teacherId}</ValueTableCell>
                  <KeyTableCell>이름</KeyTableCell>
                  <ValueTableCell>{selectedTeacher.teacherName}</ValueTableCell>
                </TableRow>
                <TableRow>
                  <KeyTableCell>소속</KeyTableCell>
                  <ValueTableCell>{selectedTeacher.schoolName}</ValueTableCell>
                  <KeyTableCell>비밀번호</KeyTableCell>
                  <ValueTableCell></ValueTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h6" my={2}>
            변경 후
          </Typography>
          <TableContainer component={Box}>
            <Table sx={{}} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <KeyTableCell>아이디</KeyTableCell>
                  <ValueTableCell>
                    {selectedTeacher.newIdRequested}
                  </ValueTableCell>
                  <KeyTableCell>이름</KeyTableCell>
                  <ValueTableCell>
                    {selectedTeacher.newNameRequested}
                  </ValueTableCell>
                </TableRow>
                <TableRow>
                  <KeyTableCell>소속</KeyTableCell>
                  <ValueTableCell>
                    {selectedTeacher.newSchoolName}
                  </ValueTableCell>
                  <KeyTableCell>비밀번호</KeyTableCell>
                  <ValueTableCell></ValueTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" sx={{ mr: 1 }} onClick={accept}>
              승인
            </Button>
            <Button variant="contained" color="error" onClick={refuse}>
              거절
            </Button>
          </Box>
        </Box>
      </Modal>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: color.mainColor1 }}>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>UID</TableCell>
              <TableCell>이름</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>휴대폰 번호</TableCell>
              <TableCell>이메일</TableCell>
              <TableCell>생년월일</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers.map((teacher) => (
              <TableRow
                key={teacher.teacherId}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "#eee" },
                  cursor: "pointer",
                }}
                onClick={() => handleTableRowClick(teacher.teacherId)}
              >
                <TableCell>{teacher.index}</TableCell>
                <TableCell>{teacher.teacherId}</TableCell>
                <TableCell>{teacher.teacherName}</TableCell>
                <TableCell>{teacher.teacherLoginId}</TableCell>
                <TableCell>{teacher.teacherPhone}</TableCell>
                <TableCell>{teacher.teacherEmail}</TableCell>
                <TableCell>
                  {teacher.teacherBirthYear}-{teacher.teacherBirthMonth}-
                  {teacher.teacherBirthDay}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPage > 0 && (
        <Pagination
          page={page}
          count={totalPage}
          onChange={(e, v) => {
            setPage(v);
          }}
        />
      )}
    </Box>
  );
}
