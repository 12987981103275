import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import authApi from "../api/auth";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setAdminId, setLoading } from "../store";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    dispatch(setAdminId({ adminId: null }));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = {
      loginId: formData.get("id"),
      password: formData.get("password"),
    };

    try {
      dispatch(setLoading({ loading: true }));
      const res = await authApi.login(data);
      if (res?.data?.isSuccess) {
        localStorage.setItem(
          "adminId",
          JSON.stringify(res.data.result.adminId)
        );
        localStorage.setItem("jwt", JSON.stringify(res.data.result.jwt));
        localStorage.setItem(
          "refreshJwt",
          JSON.stringify(res.data.result.refreshJwt)
        );
        dispatch(setAdminId({ adminId: res.data.result.adminId }));
        navigate("/");
      } else {
        alert("아이디와 비밀번호를 확인하세요");
      }
    } catch (e) {
      alert("아이디와 비밀번호를 확인하세요.");
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          로그인
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="id"
            label="아이디"
            name="id"
            autoComplete="id"
            autoFocus
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="비밀번호"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            로그인
          </Button>
          <Grid container>
            <Grid item>
              <Link
                variant="body2"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/signup");
                }}
              >
                관리자 등록
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
