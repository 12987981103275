const BASE_URL = "/web-admin/debates";
import axios from "./axios";

export default {
  getDiscussions: (adminId, params) =>
    axios.get(`${BASE_URL}/${adminId}`, {
      params,
    }),
  // getAssignment: (adminId, id) => axios.get(`${BASE_URL}/${adminId}/${id}`),
  // getList: (adminId, assignmentId, params) =>
  //   axios.get(`${BASE_URL}/${adminId}/${assignmentId}/status`, {
  //     params,
  //   }),
};
