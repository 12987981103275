const BASE_URL = "/web-admin/assignments";
import axios from "./axios";

export default {
  getAssignments: (adminId, params) =>
    axios.get(`${BASE_URL}/${adminId}`, {
      params,
    }),
  getAssignment: (adminId, id) => axios.get(`${BASE_URL}/${adminId}/${id}`),
  getList: (adminId, assignmentId, params) =>
    axios.get(`${BASE_URL}/${adminId}/${assignmentId}/status`, {
      params,
    }),
};
