import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import teacherApi from "../api/teacher";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import color from "../utils/color";
import { assignmentItem } from "../mockupdata/assignment";
import { useNavigate, useParams } from "react-router-dom";
import KeyTableCell from "../components/KeyTableCell";
import ValueTableCell from "../components/ValueTableCell";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import assignmentApi from "../api/assignment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function AssignmentDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [assignment, setAssignment] = useState({});
  const [list, setList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const fetchAssignment = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));

      const res = await assignmentApi.getAssignment(adminId, params.idx);
      if (res?.data?.isSuccess) {
        setAssignment(res.data.result);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const fetchList = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const requestParams = {
        page: page - 1,
      };
      const res = await assignmentApi.getList(
        adminId,
        params.idx,
        requestParams
      );
      if (res?.data?.isSuccess) {
        setList(res.data.result.studentSubmittedStatus);
        setTotalPage(res.data.result.totalPage);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    fetchAssignment();
  }, []);
  useEffect(() => {
    fetchList();
  }, [page]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <KeyTableCell>과제명</KeyTableCell>
              <ValueTableCell>{assignment.assignmentName}</ValueTableCell>
              <KeyTableCell>과목</KeyTableCell>
              <ValueTableCell>
                {assignment.assignmentSubjectName}
              </ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>과제 유형</KeyTableCell>
              <ValueTableCell>{assignment["assignmentType"]}</ValueTableCell>
              <KeyTableCell>마감 여부</KeyTableCell>
              <ValueTableCell>
                {assignment["assignmentProgressStatus"]}
              </ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>등록일</KeyTableCell>
              <ValueTableCell>
                {assignment["assignmentCreatedAt"]}
              </ValueTableCell>
              <KeyTableCell>기한</KeyTableCell>
              <ValueTableCell>{assignment["assignmentEndAt"]}</ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>제출률</KeyTableCell>
              <ValueTableCell>
                {assignment["assignmentSubmitRate"]}
              </ValueTableCell>
              <KeyTableCell>평균 정답률</KeyTableCell>
              <ValueTableCell>
                {assignment["assignmentCorrectionRate"]}
              </ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>세부 사항</KeyTableCell>
              <ValueTableCell colSpan={3}>
                {assignment["assignmentDetailContent"]}
              </ValueTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography
        variant="h6"
        mb={2}
        sx={{
          alignSelf: "flex-start",
        }}
      >
        과제 제출 현황 목록
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: color.mainColor1 }}>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>학생 번호</TableCell>
              <TableCell>이름</TableCell>
              <TableCell>제출 여부</TableCell>
              <TableCell>제출 날짜</TableCell>
              <TableCell>결과</TableCell>
              <TableCell>소요 시간</TableCell>
              <TableCell>만족도</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item) => (
              <TableRow
                key={item.idx}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "#eee" },
                  cursor: "pointer",
                }}
              >
                <TableCell>{item["idx"]}</TableCell>
                <TableCell>{item["studentNumber"]}</TableCell>
                <TableCell>{item["studentName"]}</TableCell>
                <TableCell>{item["assignmentSubmitStatus"]}</TableCell>
                <TableCell>{item["assignmentSubmitDate"]}</TableCell>
                <TableCell>{item["assignmentResult"]}</TableCell>
                <TableCell>{item["assignmentSolvedTime"]}</TableCell>
                <TableCell>{item["assignmentSatisfaction"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPage > 0 && (
        <Pagination
          page={page}
          count={totalPage}
          onChange={(e, v) => {
            setPage(v);
          }}
        />
      )}
    </Box>
  );
}
