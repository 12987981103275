import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import teacherApi from "../api/teacher";
import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import color from "../utils/color";
import { teachers as teacherMockup } from "../mockupdata/teacher";
import { useNavigate } from "react-router-dom";

export default function Teacher() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [teachers, setTeachers] = useState(teacherMockup);
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("teacherName");

  const fetchTeachers = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const params = { page: page - 1, [searchType]: searchValue };
      const res = await teacherApi.getTeachers(adminId, params);
      if (res?.data?.isSuccess) {
        setTeachers(res.data.result.teachers);
        setTotalPage(res.data.result.totalPage);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, [page]);

  const handleTeacherClick = (teacher) => {
    navigate(`/teacher/${teacher.index}/${teacher.teacherId}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <FormControl>
          <Select
            inputProps={{ "aria-label": "Without label" }}
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            sx={{ height: "40px" }}
          >
            <MenuItem value="teacherName">교사 이름</MenuItem>
            <MenuItem value="teacherLoginId">교사 ID</MenuItem>
          </Select>
        </FormControl>
        <Box
          component="form"
          onSubmit={(e) => {
            if (page === 1) fetchTeachers();
            else setPage(1);
            e.preventDefault();
          }}
        >
          <TextField
            id="search"
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            sx={{ ml: 2 }}
            onSubmit={(e) => {
              console.log(e);
            }}
          />
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: color.mainColor1 }}>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>UID</TableCell>
              <TableCell>이름</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>휴대폰 번호</TableCell>
              <TableCell>이메일</TableCell>
              <TableCell>생년월일</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers.map((teacher) => (
              <TableRow
                key={teacher.teacherId}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "#eee" },
                  cursor: "pointer",
                }}
                onClick={() => handleTeacherClick(teacher)}
              >
                <TableCell>{teacher.index}</TableCell>
                <TableCell>{teacher.teacherId}</TableCell>
                <TableCell>{teacher.teacherName}</TableCell>
                <TableCell>{teacher.teacherLoginId}</TableCell>
                <TableCell>{teacher.teacherPhone}</TableCell>
                <TableCell>{teacher.teacherEmail}</TableCell>
                <TableCell>
                  {teacher.teacherBirthYear}-{teacher.teacherBirthMonth}-
                  {teacher.teacherBirthDay}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPage > 0 && (
        <Pagination
          page={page}
          count={totalPage}
          onChange={(e, v) => {
            setPage(v);
          }}
        />
      )}
    </Box>
  );
}
