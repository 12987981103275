import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Header from "../components/Header";
import Navigation from "../components/Navigation";
import PrivateRoute from "../components/PrivateRoute";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import School from "../pages/School";
import Teacher from "../pages/Teacher";
import TeacherJoin from "../pages/TeacherJoin";
import TeacherModify from "../pages/TeacherModify";
import TeacherDetail from "../pages/TeacherDetail";
import Student from "../pages/Student";
import StudentDetail from "../pages/StudentDetail";
import Class from "../pages/Class";
import ClassDetail from "../pages/ClassDetail";
import Assignment from "../pages/Assignment";
import AssignmentDetail from "../pages/AssignmentDetail";
import Discussion from "../pages/Discussion";
import DiscussionDetail from "../pages/DiscussionDetail";
import Notice from "../pages/Notice";
import NoticeDetail from "../pages/NoticeDetail";

const mdTheme = createTheme();

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <BrowserRouter>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Header toggleDrawer={toggleDrawer} open={open} />
          <Navigation toggleDrawer={toggleDrawer} open={open} />
          <Box
            component="main"
            sx={{
              backgroundColor: "white",
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Box sx={{ m: 4 }}>
              <Routes>
                <Route path="/login" element={<SignIn />}></Route>
                <Route path="/signup" element={<SignUp />}></Route>
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <School />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/school"
                  element={
                    <PrivateRoute>
                      <School />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/teacher"
                  element={
                    <PrivateRoute>
                      <Teacher />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/teacher/join"
                  element={
                    <PrivateRoute>
                      <TeacherJoin />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/teacher/modify"
                  element={
                    <PrivateRoute>
                      <TeacherModify />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/teacher/:idx/:teacherId"
                  element={
                    <PrivateRoute>
                      <TeacherDetail />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/student/:id"
                  element={
                    <PrivateRoute>
                      <StudentDetail />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/student"
                  element={
                    <PrivateRoute>
                      <Student />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/class"
                  element={
                    <PrivateRoute>
                      <Class />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/class/:gradeNum/:classNum"
                  element={
                    <PrivateRoute>
                      <ClassDetail />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/assignment"
                  element={
                    <PrivateRoute>
                      <Assignment />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/assignment/:idx"
                  element={
                    <PrivateRoute>
                      <AssignmentDetail />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/discussion"
                  element={
                    <PrivateRoute>
                      <Discussion />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/discussion/:idx"
                  element={
                    <PrivateRoute>
                      <DiscussionDetail />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/notice"
                  element={
                    <PrivateRoute>
                      <Notice />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="/notice/:id"
                  element={
                    <PrivateRoute>
                      <NoticeDetail />
                    </PrivateRoute>
                  }
                ></Route>
              </Routes>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
