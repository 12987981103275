import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import classApi from "../api/classApi";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Modal,
  Pagination,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import color from "../utils/color";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function Class() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [classes, setClasses] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("teacherName");
  const [modalOpen, setModalOpen] = useState(false);

  const handleClassClick = (c) => {
    navigate(`/class/${c.gradeNum}/${c.classNum}?teacherName=${c.teacherName}`);
  };

  const fetchClasses = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const params = { page: page - 1, [searchType]: searchValue };
      const res = await classApi.getClasses(adminId, params);
      if (res?.data?.isSuccess) {
        setClasses(res.data.result.classes);
        setTotalPage(res.data.result.totalPage);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleSubmit = async (event) => {
    dispatch(setLoading({ loading: true }));
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      gradeNum: +data.get("gradeNum"),
      classNum: +data.get("classNum"),
      academicYear: +data.get("academicYear"),
      semester: +data.get("semester"),
      totalStudent: +data.get("totalStudent"),
    };
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await classApi.createClass(adminId, formData);
      if (res?.data?.isSuccess) {
        alert("학급 생성이 완료되었습니다.");
        setPage(1);
        fetchClasses();
        setModalOpen(false);
      } else {
        alert(res?.data?.message);
      }
    } catch {
      alert("학급 생성 실패");
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    fetchClasses();
  }, [page]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Button
          sx={{ height: "40px" }}
          variant="contained"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          학급 추가
        </Button>
        <Box sx={{ display: "flex" }}>
          <FormControl>
            <Select
              inputProps={{ "aria-label": "Without label" }}
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              sx={{ height: "40px" }}
            >
              <MenuItem value="teacherName">담당 교사</MenuItem>
              <MenuItem value="gradeNum">학년</MenuItem>
            </Select>
          </FormControl>
          <Box
            component="form"
            onSubmit={(e) => {
              if (page === 1) fetchClasses();
              else setPage(1);
              e.preventDefault();
            }}
          >
            <TextField
              id="search"
              variant="outlined"
              sx={{ alignSelf: "flex-end", mb: 2, width: "250px", ml: 2 }}
              size="small"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </Box>
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: color.mainColor1 }}>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>학사년도</TableCell>
              <TableCell>학기</TableCell>
              <TableCell>학년</TableCell>
              <TableCell>학급</TableCell>
              <TableCell>학급 학생 수</TableCell>
              <TableCell>담당 교사</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classes.map((classItem) => (
              <TableRow
                key={classItem.idx}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "#eee" },
                  cursor: "pointer",
                }}
                onClick={() => handleClassClick(classItem)}
              >
                <TableCell>{classItem.idx}</TableCell>
                <TableCell>{classItem.academicYear}</TableCell>
                <TableCell>{classItem.semester}</TableCell>
                <TableCell>{classItem.gradeNum}</TableCell>
                <TableCell>{classItem.classNum}</TableCell>
                <TableCell>{classItem.totalStudent}</TableCell>
                <TableCell>{classItem.teacherName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPage > 0 && (
        <Pagination
          page={page}
          count={totalPage}
          onChange={(e, v) => {
            setPage(v);
          }}
        />
      )}
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Box
          sx={style}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          gap={3}
        >
          <TextField
            fullWidth
            id="gradeNum"
            label="학년"
            name="gradeNum"
            sx={{ mb: 2 }}
            type="number"
          />
          <TextField
            fullWidth
            id="classNum"
            label="학급"
            name="classNum"
            sx={{ mb: 2 }}
            type="number"
          />
          <TextField
            fullWidth
            id="academicYear"
            label="학사년도"
            name="academicYear"
            sx={{ mb: 2 }}
            type="number"
          />
          <TextField
            fullWidth
            id="semester"
            label="학기"
            name="semester"
            sx={{ mb: 2 }}
            type="number"
          />
          <TextField
            fullWidth
            id="totalStudent"
            label="학급 학생 수"
            name="totalStudent"
            sx={{ mb: 2 }}
            type="number"
          />
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" sx={{ mr: 1 }} type="submit">
              저장
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
