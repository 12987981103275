import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import teacherApi from "../api/teacher";
import schoolApi from "../api/school";

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import color from "../utils/color";
import { teacher as teacherMockup } from "../mockupdata/teacher";
import { useNavigate, useParams } from "react-router-dom";
import KeyTableCell from "../components/KeyTableCell";
import ValueTableCell from "../components/ValueTableCell";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function TeacherDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [teacher, setTeacher] = useState({});
  const [classList, setClassList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [birth, setBirth] = useState("");

  const [numClasses, setNumClasses] = useState([]);
  const [classes, setClasses] = useState([[], [], []]);

  const handleModifyButtonClick = () => {
    setModalOpen(true);
  };

  const handleDeleteButtonClick = () => {
    setDialogOpen(true);
  };

  const deleteTeacher = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await teacherApi.deleteTeacher(adminId, teacher.teacherId);
      if (res?.data?.isSuccess) {
        alert("삭제 완료");
        navigate("/teacher", { replace: true });
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
    setDialogOpen(false);
  };

  const fetchTeacher = async () => {
    const adminId = JSON.parse(localStorage.getItem("adminId"));
    const requestParams = { idx: params.teacherId };
    const res = await teacherApi.getTeacher(adminId, requestParams);

    if (res?.data?.isSuccess) {
      setTeacher(res.data.result);
    } else {
      alert(res?.data?.message);
    }
  };

  const fetchClassInfo = async () => {
    const adminId = JSON.parse(localStorage.getItem("adminId"));
    const teacherId = params.teacherId;
    const res = await teacherApi.getTeacherClass(adminId, teacherId);
    if (res?.data?.isSuccess) {
      const newClasses = [[], [], []];
      res.data.result.forEach((classItem) => {
        newClasses[classItem.gradeNum - 1].push(classItem.classNum);
      });
      setClasses(newClasses);
      setClassList(res.data.result);
    } else {
      alert(res?.data?.message);
    }
  };

  const fetchSchoolInfo = async () => {
    const adminId = JSON.parse(localStorage.getItem("adminId"));
    const res = await schoolApi.getSchool(adminId);
    if (res?.data?.isSuccess) {
      setNumClasses([
        res.data.result.firstGrade.numberOfClass,
        res.data.result.secondGrade.numberOfClass,
        res.data.result.thirdGrade.numberOfClass,
      ]);
    } else {
      alert(res?.data?.message);
    }
  };

  const fetchAll = async () => {
    dispatch(setLoading({ loading: true }));
    try {
      await fetchTeacher();
      await fetchClassInfo();
      await fetchSchoolInfo();
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const [teacherBirthYear, teacherBirthMonth, teacherBirthDay] =
      birth.split("-");

    const formData = {
      teacherName: data.get("name"),
      teacherPhone: data.get("mobile"),
      teacherEmail: data.get("email"),
      teacherBirthYear,
      teacherBirthMonth,
      teacherBirthDay,
      firstGrade: classes[0],
      secondGrade: classes[1],
      thirdGrade: classes[2],
    };

    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await teacherApi.updateTeacher(
        adminId,
        params.teacherId,
        formData
      );
      if (res?.data?.isSuccess) {
        alert("수정이 완료되었습니다.");
        fetchTeacher();
        fetchClassInfo();
        setModalOpen(false);
      } else {
        alert(res?.data?.message);
      }
    } catch {
      alert("정보 수정 실패");
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    setBirth(
      `${teacher.teacherBirthYear}-${teacher.teacherBirthMonth}-${teacher.teacherBirthDay}`
    );
  }, [teacher]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 2 }}>
          <DialogContentText id="alert-dialog-description" color="black">
            정말 삭제하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setDialogOpen(false)}
            variant="contained"
            sx={{ backgroundColor: "gray" }}
            size="small"
          >
            취소
          </Button>
          <Button
            onClick={deleteTeacher}
            variant="contained"
            color="error"
            size="small"
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Box
          sx={style}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          gap={3}
        >
          <TextField
            fullWidth
            id="name"
            label="이름"
            name="name"
            sx={{ mb: 2 }}
            defaultValue={teacher.teacherName}
          />
          <TextField
            fullWidth
            id="mobile"
            label="휴대폰 번호"
            name="mobile"
            sx={{ mb: 2 }}
            defaultValue={teacher.teacherPhone}
          />
          <TextField
            fullWidth
            id="email"
            label="이메일"
            name="email"
            sx={{ mb: 2 }}
            defaultValue={teacher.teacherEmail}
          />
          <TextField
            id="birth"
            label="생년월일"
            type="date"
            defaultValue={`${teacher.teacherBirthYear}-${teacher.teacherBirthMonth}-${teacher.teacherBirthDay}`}
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setBirth(e.target.value);
            }}
          />

          <TableContainer component={Box}>
            <Table sx={{}} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <KeyTableCell>담당 학급</KeyTableCell>
                  <ValueTableCell colSpan={3}>
                    {numClasses.map((num, i) => (
                      <Box key={i}>
                        <Typography variant="h6">{i + 1}학년</Typography>
                        <FormGroup
                          sx={{ display: "flex", flexDirection: "row" }}
                          onChange={(e) => {
                            const newClasses = [...classes];
                            const gradeClasses = newClasses[i];
                            if (e.target.checked) {
                              gradeClasses.push(Number(e.target.value));
                            } else {
                              gradeClasses.splice(
                                gradeClasses.findIndex(
                                  (v) => v === Number(e.target.value)
                                ),
                                1
                              );
                            }
                            setClasses(newClasses);
                          }}
                        >
                          {new Array(num).fill(0).map((_, index) => (
                            <FormControlLabel
                              control={<Checkbox size="small" />}
                              label={`${index + 1}반`}
                              value={index + 1}
                              key={index}
                              checked={classes[i].includes(index + 1)}
                            />
                          ))}
                        </FormGroup>
                      </Box>
                    ))}
                  </ValueTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => setModalOpen(false)}
              variant="contained"
              sx={{ backgroundColor: "gray", mr: 2 }}
            >
              취소
            </Button>
            <Button variant="contained" sx={{ mr: 1 }} type="submit">
              저장
            </Button>
          </Box>
        </Box>
      </Modal>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <KeyTableCell>UID</KeyTableCell>
              <ValueTableCell>{teacher.teacherId}</ValueTableCell>
              <KeyTableCell>이름</KeyTableCell>
              <ValueTableCell>{teacher.teacherName}</ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>ID</KeyTableCell>
              <ValueTableCell>{teacher.teacherLoginId}</ValueTableCell>
              <KeyTableCell>휴대폰 번호</KeyTableCell>
              <ValueTableCell>{teacher.teacherPhone}</ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>이메일</KeyTableCell>
              <ValueTableCell colSpan={3}>
                {teacher.teacherEmail}
              </ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>생년월일</KeyTableCell>
              <ValueTableCell colSpan={3}>
                {teacher.teacherBirthYear}-{teacher.teacherBirthMonth}-
                {teacher.teacherBirthDay}
              </ValueTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6" mb={2}>
        담당 학급 목록
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: color.mainColor1 }}>
            <TableRow>
              <TableCell>학년</TableCell>
              <TableCell>학급</TableCell>
              <TableCell>학생 수</TableCell>
              <TableCell>진행 과제 수</TableCell>
              <TableCell>완료 과제 수</TableCell>
              <TableCell>과제 진행률</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classList.map((classItem, i) => (
              <TableRow
                key={i}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "#eee" },
                  cursor: "pointer",
                }}
              >
                <TableCell>{classItem.gradeNum}</TableCell>
                <TableCell>{classItem.classNum}</TableCell>
                <TableCell>{classItem.totalStudent}</TableCell>
                <TableCell>{classItem.totalAssignmentCount}</TableCell>
                <TableCell>{classItem.completedAssignmentCount}</TableCell>
                <TableCell>
                  {classItem.rateOfCompletedAssignment.toFixed(2)}%
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          onClick={handleModifyButtonClick}
        >
          수정
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteButtonClick}
        >
          삭제
        </Button>
      </Box>
    </Box>
  );
}
