const BASE_URL = "/web-admin";
import axios from "./axios";

export default {
  getJoinRequestTeachers: (adminId, params) =>
    axios.get(`${BASE_URL}/requests/teachers/${adminId}`, {
      params,
    }),
  getJoinRequestTeacher: (adminId, teacherId) =>
    axios.get(`${BASE_URL}/requests/teachers/${adminId}/${teacherId}`),

  acceptRequestTeacher: (adminId, teacherId, data) =>
    axios.post(`${BASE_URL}/requests/teachers/${adminId}/${teacherId}`, data),

  getModifyRequestTeachers: (adminId, params) =>
    axios.get(`${BASE_URL}/requests/teachers/${adminId}/corrections`, {
      params,
    }),
  getModifyRequestTeacher: (adminId, teacherId) =>
    axios.get(
      `${BASE_URL}/requests/teachers/${adminId}/corrections/${teacherId}`
    ),

  getTeachers: (adminId, params) =>
    axios.get(`${BASE_URL}/teachers/${adminId}`, {
      params,
    }),

  getTeacher: (adminId, params) =>
    axios.get(`${BASE_URL}/teachers/${adminId}/select`, {
      params,
    }),

  getTeacherClass: (adminId, teacherId) =>
    axios.get(`${BASE_URL}/teachers/${adminId}/select/${teacherId}`),

  deleteTeacher: (adminId, teacherId) =>
    axios.delete(`${BASE_URL}/teachers/${adminId}/${teacherId}`),

  refuseTeacherJoin: (teacherId) =>
    axios.delete(`${BASE_URL}/requests/teachers/${teacherId}`),

  refuseTeacherModify: (teacherId) =>
    axios.delete(`${BASE_URL}/requests/teachers/corrections/${teacherId}`),

  acceptTeacherModify: (adminId, teacherId) =>
    axios.patch(
      `${BASE_URL}/requests/teachers/${adminId}/corrections/${teacherId}`
    ),

  updateTeacher: (adminId, teacherId, data) =>
    axios.patch(`${BASE_URL}/teachers/${adminId}/${teacherId}`, data),
};
