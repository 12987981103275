import React, { useEffect, useState, useRef } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import studentApi from "../api/student";
import schoolApi from "../api/school";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Pagination,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import color from "../utils/color";
import { useNavigate, useParams } from "react-router-dom";
import KeyTableCell from "../components/KeyTableCell";
import ValueTableCell from "../components/ValueTableCell";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};

export default function StudentDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [student, setStudent] = useState({});
  const [assignments, setAssignments] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [birth, setBirth] = useState("");
  const [studentGrade, setStudentGrade] = useState(1);
  const [studentClass, setStudentClass] = useState(1);
  const [numClasses, setNumClasses] = useState([0, 0, 0]);

  const ref = useRef(null);

  const fetchSchoolInfo = async () => {
    try {
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await schoolApi.getSchool(adminId);
      if (res?.data?.isSuccess) {
        setNumClasses([
          res.data.result.firstGrade.numberOfClass,
          res.data.result.secondGrade.numberOfClass,
          res.data.result.thirdGrade.numberOfClass,
        ]);
      } else {
        alert(res?.data?.message);
      }
    } catch {
      return;
    }
  };

  const handleModifyButtonClick = () => {
    setModalOpen(true);
  };

  const handleDeleteButtonClick = () => {
    setDialogOpen(true);
  };

  const handleClickApprovalButton = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await studentApi.approveStudent(adminId, params.id);
      if (res?.data?.isSuccess) {
        alert("승인 완료");
        fetchStudent();
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const deleteStudent = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const res = await studentApi.deleteStudent(params.id);
      if (res?.data?.isSuccess) {
        alert("삭제 완료");
        navigate("/student", { replace: true });
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
    setDialogOpen(false);
  };

  const fetchStudent = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await studentApi.getStudent(adminId, params.id);
      if (res?.data?.isSuccess) {
        setStudent(res.data.result);
        setStudentGrade(res.data.result.gradeNum);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const fetchStudentAssignments = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const requestParams = {
        page: page - 1,
      };
      const res = await studentApi.getStudentAssignments(
        params.id,
        requestParams
      );
      if (res?.data?.isSuccess) {
        setAssignments(res.data.result.studentAssignments);
        setTotalPage(res.data.result.totalPage);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const [studentBirthYear, studentBirthMonth, studentBirthDay] =
      birth.split("-");

    const formData = {
      studentName: data.get("name"),
      studentPhone: data.get("mobile"),
      studentEmail: data.get("email"),
      studentBirthYear,
      studentBirthMonth,
      studentBirthDay,
      gradeNum: Number(data.get("grade")),
      classNum: Number(data.get("class")),
    };

    for (let a of data.entries()) {
      console.log(a);
    }

    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await studentApi.updateStudent(adminId, params.id, formData);
      if (res?.data?.isSuccess) {
        alert("수정이 완료되었습니다.");
        fetchStudent();
        setModalOpen(false);
      } else {
        alert(res?.data?.message);
      }
    } catch {
      alert("정보 수정 실패");
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    fetchStudent();
    fetchSchoolInfo();
  }, []);

  useEffect(() => {
    fetchStudentAssignments();
  }, [page]);

  useEffect(() => {
    setBirth(
      `${student.studentBirthYear}-${student.studentBirthMonth}-${student.studentBirthDay}`
    );
  }, [student]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 2 }}>
          <DialogContentText id="alert-dialog-description" color="black">
            정말 삭제하시겠습니까?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setDialogOpen(false)}
            variant="contained"
            sx={{ backgroundColor: "gray" }}
            size="small"
          >
            취소
          </Button>
          <Button
            onClick={deleteStudent}
            variant="contained"
            color="error"
            size="small"
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Box
          sx={style}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          gap={3}
        >
          <TextField
            fullWidth
            id="name"
            label="이름"
            name="name"
            defaultValue={student.studentName}
          />
          <TextField
            fullWidth
            id="mobile"
            label="휴대폰 번호"
            name="mobile"
            defaultValue={student.studentPhone}
          />
          <TextField
            fullWidth
            id="email"
            label="이메일"
            name="email"
            defaultValue={student.studentEmail}
          />
          <TextField
            id="birth"
            label="생년월일"
            type="date"
            defaultValue={`${student.studentBirthYear}-${student.studentBirthMonth}-${student.studentBirthDay}`}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setBirth(e.target.value);
            }}
          />

          <FormControl>
            <FormLabel id="grade">학년</FormLabel>
            <RadioGroup
              row
              name="grade"
              value={studentGrade}
              onChange={(e) => {
                setStudentGrade(Number(e.target.value));
                setStudentClass(1);
              }}
            >
              <FormControlLabel value="1" control={<Radio />} label="1학년" />
              <FormControlLabel value="2" control={<Radio />} label="2학년" />
              <FormControlLabel value="3" control={<Radio />} label="3학년" />
            </RadioGroup>
          </FormControl>

          <FormControl>
            <FormLabel id="class">학급</FormLabel>
            <RadioGroup
              row
              name="class"
              value={studentClass}
              onChange={(e) => {
                setStudentClass(Number(e.target.value));
              }}
            >
              {new Array(numClasses[studentGrade - 1])
                .fill(0)
                .map((_, index) => (
                  <FormControlLabel
                    value={index + 1}
                    control={<Radio />}
                    label={`${index + 1}반`}
                  />
                ))}
            </RadioGroup>
          </FormControl>

          {/* <TextField
            id="studentNum"
            label="번호"
            type="number"
            defaultValue={student.studentNum}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            name="studentNum"
          /> */}

          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
              gap: "8px",
            }}
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: "gray" }}
              onClick={() => setModalOpen(false)}
            >
              취소
            </Button>
            <Button variant="contained" type="submit">
              저장
            </Button>
          </Box>
        </Box>
      </Modal>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <KeyTableCell>UID</KeyTableCell>
              <ValueTableCell>{student.studentId}</ValueTableCell>
              <KeyTableCell>이름</KeyTableCell>
              <ValueTableCell>{student.studentName}</ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>ID</KeyTableCell>
              <ValueTableCell>{student.studentLoginId}</ValueTableCell>
              <KeyTableCell>학년</KeyTableCell>
              <ValueTableCell>{student.gradeNum}</ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>학급</KeyTableCell>
              <ValueTableCell>{student.classNum}</ValueTableCell>
              <KeyTableCell>번호</KeyTableCell>
              <ValueTableCell>{student.studentNum}</ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>휴대폰 번호</KeyTableCell>
              <ValueTableCell>{student.studentPhone}</ValueTableCell>
              <KeyTableCell>이메일</KeyTableCell>
              <ValueTableCell>{student.studentEmail}</ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>생년월일</KeyTableCell>
              <ValueTableCell>
                {student.studentBirthYear}-{student.studentBirthMonth}-
                {student.studentBirthDay}
              </ValueTableCell>
              <KeyTableCell>과제 제출률</KeyTableCell>
              <ValueTableCell>
                {student.rateOfCompletedAssignment}%
              </ValueTableCell>
            </TableRow>
            <TableRow>
              <KeyTableCell>집중도 점수</KeyTableCell>
              <ValueTableCell>{student.concentrationScore}</ValueTableCell>
              <KeyTableCell>스티커 개수</KeyTableCell>
              <ValueTableCell>{student.numberOfSticker}</ValueTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6" mb={2}>
        과제 제출 현황 목록
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: color.mainColor1 }}>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>과제명</TableCell>
              <TableCell>과제 제출 여부</TableCell>
              <TableCell>제출 일시</TableCell>
              <TableCell>점수</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assignments?.map((item) => (
              <TableRow
                key={item.index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "#eee" },
                  cursor: "pointer",
                }}
              >
                <TableCell>{item.index}</TableCell>
                <TableCell>{item.studentAssignmentName}</TableCell>
                <TableCell>
                  {item.studentAssignmentSubmittedStatus === "SUBMITTED"
                    ? "제출"
                    : "미제출"}
                </TableCell>
                <TableCell>{item.studentSubmittedAssignmentDate}</TableCell>
                <TableCell>{item.studentAssignmentScore}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPage > 0 && (
        <Pagination
          page={page}
          count={totalPage}
          onChange={(e, v) => {
            setPage(v);
          }}
          sx={{ display: "flex", justifyContent: "center" }}
        />
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          onClick={handleClickApprovalButton}
          disabled={student.isApproval === "Y"}
        >
          승인
        </Button>
        <Button
          variant="contained"
          sx={{ mr: 1 }}
          onClick={handleModifyButtonClick}
        >
          수정
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteButtonClick}
        >
          삭제
        </Button>
      </Box>
    </Box>
  );
}
