import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import studentApi from "../api/student";
import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import color from "../utils/color";
import { students as studentsMokup } from "../mockupdata/student";
import { useNavigate } from "react-router-dom";

export default function Student() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [students, setStudents] = useState(studentsMokup);
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState("name");

  const handleStudentClick = (student) => {
    navigate(`/student/${student.studentId}`);
  };

  const fetchStudents = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const params = { page: page - 1, [searchType]: searchValue };
      const res = await studentApi.getStudents(adminId, params);
      if (res?.data?.isSuccess) {
        setStudents(res.data.result.students);
        setTotalPage(res.data.result.totalPage);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  useEffect(() => {
    fetchStudents();
  }, [page]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <FormControl>
          <Select
            inputProps={{ "aria-label": "Without label" }}
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            sx={{ height: "40px" }}
          >
            <MenuItem value="name">이름</MenuItem>
            <MenuItem value="loginId">ID</MenuItem>
            <MenuItem value="grade">학년</MenuItem>
          </Select>
        </FormControl>
        <Box
          component="form"
          onSubmit={(e) => {
            if (page === 1) fetchStudents();
            else setPage(1);
            e.preventDefault();
          }}
        >
          <TextField
            id="search"
            variant="outlined"
            sx={{ alignSelf: "flex-end", mb: 2, width: "250px", ml: 2 }}
            size="small"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: color.mainColor1 }}>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>UID</TableCell>
              <TableCell>이름</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>학년</TableCell>
              <TableCell>학급</TableCell>
              <TableCell>번호</TableCell>
              <TableCell>휴대폰 번호</TableCell>
              <TableCell>이메일</TableCell>
              <TableCell>생년월일</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student) => (
              <TableRow
                key={student.index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "#eee" },
                  cursor: "pointer",
                }}
                onClick={() => handleStudentClick(student)}
              >
                <TableCell>{student.index}</TableCell>
                <TableCell>{student.studentId}</TableCell>
                <TableCell>{student.studentName}</TableCell>
                <TableCell>{student.studentLoginId}</TableCell>
                <TableCell>{student.studentGradeNum}</TableCell>
                <TableCell>{student.studentClassNum}</TableCell>
                <TableCell>{student.studentNum}</TableCell>
                <TableCell>{student.studentPhone}</TableCell>
                <TableCell>{student.studentEmail}</TableCell>
                <TableCell>
                  {student.studentBirthYear}-{student.studentBirthMonth}-
                  {student.studentBirthDay}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPage > 0 && (
        <Pagination
          page={page}
          count={totalPage}
          onChange={(e, v) => {
            setPage(v);
          }}
        />
      )}
    </Box>
  );
}
