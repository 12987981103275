const BASE_URL = "/web-admin/students";
import axios from "./axios";

export default {
  getStudents: (adminId, params) =>
    axios.get(`${BASE_URL}/${adminId}`, {
      params,
    }),
  getStudent: (adminId, studentId) =>
    axios.get(`${BASE_URL}/${adminId}/${studentId}`),
  getStudentAssignments: (studentId, params) =>
    axios.get(`${BASE_URL}/${studentId}/assignments`, {
      params,
    }),
  deleteStudent: (studentId) => axios.delete(`${BASE_URL}/${studentId}`),
  updateStudent: (adminId, studentId, data) =>
    axios.patch(`${BASE_URL}/${adminId}/${studentId}`, data),
  approveStudent: (adminId, studentId) =>
    axios.patch(`${BASE_URL}/${adminId}/${studentId}/approve`),
};
