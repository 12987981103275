export const discussionList = new Array(9).fill(0).map((_, i) => ({
  index: i + 1,
  "질문 번호": i + 1,
  제목: `제목${i + 1}`,
  작성자: `작성자${i + 1}`,
  작성일: `2022-11-0${i + 1}`,
  "답변 여부": i % 2 === 0 ? "답변" : "미답변",
}));

export const discussionItem = {
  "질문 제목": "질문 제목",
  "질문 내용":
    "질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용질문 내용",
  작성자: "작성자",
  "작성 일시": "2022-11-03 13:50:00",

  answers: new Array(2).fill(0).map((_, i) => ({
    index: i + 1,
    "답변 제목": `답변${i + 1}`,
    "답변 내용": `답변 내용${i + 1}`,
    "답변 작성자": `답변 작성자${i + 1}`,
    "답변 일시": `2022-11-03 14:50:00`,
  })),
};
