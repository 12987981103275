const BASE_URL = "/web-admin/notices";
import axios from "./axios";

export default {
  getNotices: (adminId, params) =>
    axios.get(`${BASE_URL}/${adminId}`, {
      params,
    }),
  getNotice: (adminId, id) => axios.get(`${BASE_URL}/${adminId}/${id}`),
  createNotice: (adminId, data) => axios.post(`${BASE_URL}/${adminId}`, data),
  deleteNotice: (adminId, noticeId) =>
    axios.delete(`${BASE_URL}/${adminId}/${noticeId}`),
  updateNotice: (adminId, noticeId, data) =>
    axios.patch(`${BASE_URL}/${adminId}/${noticeId}`, data),
};
