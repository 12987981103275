import React, { useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { setLoading } from "../store";
import teacherApi from "../api/teacher";
import schoolApi from "../api/school";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Pagination,
  Typography,
} from "@mui/material";
import color from "../utils/color";
import KeyTableCell from "../components/KeyTableCell";
import ValueTableCell from "../components/ValueTableCell";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function TeacherJoin() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [teachers, setTeachers] = useState([
    {
      index: 0,
      teacherId: 0,
      teacherName: "김수미",
      teacherLoginId: "teacher123",
      teacherPhone: "010-1234-1234",
      teacherEmail: "teacher123@gmail.com",
      teacherBirthYear: "1990",
      teacherBirthMonth: "12",
      teacherBirthDay: "12",
    },
  ]);
  const [selectedTeacher, setSelectedTeacher] = useState({
    teacherId: 0,
    teacherName: "김수미",
    teacherLoginId: "teacher123",
    teacherPhone: "010-1234-1234",
    teacherEmail: "teacher123@gmail.com",
    teacherBirthYear: "1990",
    teacherBirthMonth: "12",
    teacherBirthDay: "12",
  });
  const [modalOpen, setModalOpen] = useState(false);

  const [numClasses, setNumClasses] = useState([]);
  const [classes, setClasses] = useState([[], [], []]);

  useEffect(() => {
    console.log(classes);
  }, [classes]);

  const fetchTeachers = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const params = { page: page - 1 };
      const res = await teacherApi.getJoinRequestTeachers(adminId, params);
      if (res?.data?.isSuccess) {
        setTeachers(res.data.result.teachers);
        setTotalPage(res.data.result.totalPage);
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const fetchSelectedTeacher = async (teacherId) => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await teacherApi.getJoinRequestTeacher(adminId, teacherId);
      if (res?.data?.isSuccess) {
        setSelectedTeacher({ ...selectedTeacher, ...res.data.result });
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const refuse = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const res = await teacherApi.refuseTeacherJoin(selectedTeacher.teacherId);
      if (res?.data?.isSuccess) {
        alert("요청이 거절되었습니다.");
        setModalOpen(false);
        fetchTeachers();
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const accept = async () => {
    try {
      dispatch(setLoading({ loading: true }));
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const data = {
        firstGrade: classes[0],
        secondGrade: classes[1],
        thirdGrade: classes[2],
        ...selectedTeacher,
      };
      const res = await teacherApi.acceptRequestTeacher(
        adminId,
        selectedTeacher.teacherId,
        data
      );
      if (res?.data?.isSuccess) {
        alert("요청이 승인되었습니다.");
        setModalOpen(false);
        fetchTeachers();
      } else {
        alert(res?.data?.message);
      }
    } finally {
      dispatch(setLoading({ loading: false }));
    }
  };

  const fetchSchoolInfo = async () => {
    try {
      const adminId = JSON.parse(localStorage.getItem("adminId"));
      const res = await schoolApi.getSchool(adminId);
      if (res?.data?.isSuccess) {
        setNumClasses([
          res.data.result.firstGrade.numberOfClass,
          res.data.result.secondGrade.numberOfClass,
          res.data.result.thirdGrade.numberOfClass,
        ]);
      } else {
        alert(res?.data?.message);
      }
    } catch {
      return;
    }
  };

  useEffect(() => {
    fetchTeachers();
    fetchSchoolInfo();
  }, [page]);

  useEffect(() => {
    if (modalOpen) setClasses([[], [], []]);
  }, [modalOpen]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Box sx={style}>
          <TableContainer component={Box}>
            <Table sx={{}} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <KeyTableCell>UID</KeyTableCell>
                  <ValueTableCell>{selectedTeacher.teacherId}</ValueTableCell>
                  <KeyTableCell>이름</KeyTableCell>
                  <ValueTableCell>{selectedTeacher.teacherName}</ValueTableCell>
                </TableRow>
                <TableRow>
                  <KeyTableCell>ID</KeyTableCell>
                  <ValueTableCell>
                    {selectedTeacher.teacherLoginId}
                  </ValueTableCell>
                  <KeyTableCell>휴대폰 번호</KeyTableCell>
                  <ValueTableCell>
                    {selectedTeacher.teacherPhone}
                  </ValueTableCell>
                </TableRow>
                <TableRow>
                  <KeyTableCell>이메일</KeyTableCell>
                  <ValueTableCell colSpan={3}>
                    {selectedTeacher.teacherEmail}
                  </ValueTableCell>
                </TableRow>
                <TableRow>
                  <KeyTableCell>생년월일</KeyTableCell>
                  <ValueTableCell colSpan={3}>
                    {selectedTeacher.teacherBirthYear}-
                    {selectedTeacher.teacherBirthMonth}-
                    {selectedTeacher.teacherBirthDay}
                  </ValueTableCell>
                </TableRow>
                <TableRow>
                  <KeyTableCell>담당 학급</KeyTableCell>
                  <ValueTableCell colSpan={3}>
                    {numClasses.map((num, i) => (
                      <Box key={i}>
                        <Typography variant="h6">{i + 1}학년</Typography>
                        <FormGroup
                          sx={{ display: "flex", flexDirection: "row" }}
                          onChange={(e) => {
                            const newClasses = [...classes];
                            const gradeClasses = newClasses[i];
                            if (e.target.checked) {
                              gradeClasses.push(Number(e.target.value));
                            } else {
                              gradeClasses.splice(
                                gradeClasses.findIndex(
                                  (v) => v === Number(e.target.value)
                                ),
                                1
                              );
                            }
                            setClasses(newClasses);
                          }}
                        >
                          {new Array(num).fill(0).map((_, index) => (
                            <FormControlLabel
                              control={<Checkbox size="small" />}
                              label={`${index + 1}반`}
                              value={index + 1}
                              key={index}
                            />
                          ))}
                        </FormGroup>
                      </Box>
                    ))}
                  </ValueTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" sx={{ mr: 1 }} onClick={accept}>
              승인
            </Button>
            <Button variant="contained" color="error" onClick={refuse}>
              거절
            </Button>
          </Box>
        </Box>
      </Modal>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: color.mainColor1 }}>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>UID</TableCell>
              <TableCell>이름</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>휴대폰 번호</TableCell>
              <TableCell>이메일</TableCell>
              <TableCell>생년월일</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers.map((teacher) => (
              <TableRow
                key={teacher.teacherId}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": { backgroundColor: "#eee" },
                  cursor: "pointer",
                }}
                onClick={() => {
                  setModalOpen(true);
                  fetchSelectedTeacher(teacher.teacherId);
                }}
              >
                <TableCell>{teacher.index}</TableCell>
                <TableCell>{teacher.teacherId}</TableCell>
                <TableCell>{teacher.teacherName}</TableCell>
                <TableCell>{teacher.teacherLoginId}</TableCell>
                <TableCell>{teacher.teacherPhone}</TableCell>
                <TableCell>{teacher.teacherEmail}</TableCell>
                <TableCell>
                  {teacher.teacherBirthYear}-{teacher.teacherBirthMonth}-
                  {teacher.teacherBirthDay}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPage > 0 && (
        <Pagination
          page={page}
          count={totalPage}
          onChange={(e, v) => {
            setPage(v);
          }}
        />
      )}
    </Box>
  );
}
