export const classList = new Array(9).fill(0).map((_, i) => ({
  index: i + 1,
  학사년도: 2010 + i + 1,
  학기: i + 1,
  학년: ((i + 1) % 3) + 1,
  학급: i + 1,
  "학급 학생 수": 20 + i + 1,
  "담당 교사": `김교사${i}`,
}));

export const classItem = {
  학사년도: 2001,
  학기: 1,
  "담당 교사": "김교사",
  "담당 교사 과목": "수학",
  "학급 학생 수": 30,
  "평균 집중도": 50,
  "누적 과제 수": 10,
  "진행 과제 수": 5,
  students: new Array(9).fill(0).map((_, i) => ({
    index: i + 1,
    "학생 이름": `학생${i + 1}`,
    "학생 번호": i + 1,
    "과제 제출률": i + 1,
    "집중도 점수": i + 1,
  })),
};
